var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"ma-5 order-detail"},[_c('h2',[_vm._v("Chi tiết thông tin đơn hàng")]),_c('h4',{staticClass:"mt-4"},[_vm._v("1.Thông tin khách hàng")]),_c('div',[_c('span',{staticClass:"font-weight-bold"},[_vm._v("Họ và tên : ")]),_c('span',[_vm._v(_vm._s(_vm.order.full_name))])]),_c('div',[_c('span',{staticClass:"font-weight-bold"},[_vm._v("Email : ")]),_c('span',[_vm._v(_vm._s(_vm.order.email))])]),_c('div',[_c('span',{staticClass:"font-weight-bold"},[_vm._v("Số điện thoại : ")]),_c('span',[_vm._v(_vm._s(_vm.order.phone_number))])]),_c('div',[_c('span',{staticClass:"font-weight-bold"},[_vm._v("Địa chỉ : ")]),_c('span',[_vm._v(_vm._s(_vm.order.address))])]),_c('v-divider'),_c('h4',{staticClass:"mt-4"},[_vm._v(" 2.Thông tin đơn hàng")]),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.order.order_detail,"hide-default-footer":""},scopedSlots:_vm._u([{key:"item.title",fn:function(ref){
var item = ref.item;
return [(item.book)?_c('div',{staticClass:"d-flex align-center"},[_c('span',[_c('img',{attrs:{"src":item.book.url,"width":"100px"}})]),_c('span',{staticClass:"ml-2"},[_vm._v(_vm._s(item.book.title))])]):_vm._e()]}},{key:"item.number",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center justify-center"},[_vm._v(" "+_vm._s(item.number)+" ")])]}},{key:"item.price",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-center"},[_vm._v(_vm._s(item.amount ? _vm.formatPrice(parseFloat(item.amount) / item.number) : '')+"đ")])]}},{key:"item.total",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-center"},[_vm._v(" "+_vm._s(item.book ? _vm.formatPrice(parseFloat(item.amount) ) : '')+"đ")])]}}])}),_c('v-divider'),_c('h4',{staticClass:"mt-4"},[_vm._v("3.Chi tiết")]),_c('div',[_c('span',{staticClass:"font-weight-bold"},[_vm._v("Ngày mua : ")]),_c('span',[_vm._v(_vm._s(_vm.order.register_date))])]),_c('div',[_c('span',{staticClass:"font-weight-bold"},[_vm._v("Mã giảm giá: ")]),(_vm.order.discount_code_id && _vm.order.discount)?_c('span',[_vm._v(_vm._s(_vm.order.discount.discount_code))]):(_vm.order.affiliate_code_id && _vm.order.affiliate)?_c('span',[_vm._v(_vm._s(_vm.order.affiliate.code_number))]):_c('span',[_vm._v("Không có mã giảm giá")])]),_c('div',[_c('span',{staticClass:"font-weight-bold"},[_vm._v("Giá : ")]),_c('span',[_vm._v(_vm._s(_vm.formatPrice(_vm.totalMoney))+" Đ")])]),_c('div',[_c('span',{staticClass:"font-weight-bold"},[_vm._v("Số tiền phải trả: ")]),_c('span',[_vm._v(_vm._s(_vm.formatPrice(_vm.order.current_price_course))+" Đ")])]),_c('div',[_c('span',{staticClass:"font-weight-bold"},[_vm._v("Hình thức thanh toán: ")]),(_vm.order.payment_category_id == 1)?_c('span',[_vm._v("Chuyển khoản ngân hàng tại Việt Nam")]):_vm._e(),(_vm.order.payment_category_id == 2)?_c('span',[_vm._v("Chuyển phát nhanh toàn quốc")]):_vm._e(),(_vm.order.payment_category_id == 3)?_c('span',[_vm._v("Thanh toán qua MoMo")]):_vm._e()]),_c('v-divider'),_c('h4',{staticClass:"mt-4"},[_vm._v("4.Cập nhật trạng thái đơn hàng")]),_c('validation-observer',{ref:"send"},[_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('div',[_c('span',{staticClass:"font-weight-bold mb-2"},[_vm._v("Trạng thái thanh toán: ")]),_c('ValidationObserver',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"items":_vm.orders_status,"dense":"","outlined":""},model:{value:(_vm.status),callback:function ($$v) {_vm.status=$$v},expression:"status"}}),_c('span',{staticClass:"validate-error text-danger text-danger"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}])})],1)]),_c('v-col',{attrs:{"cols":"6"}},[_c('span',{staticClass:"font-weight-bold mb-2"},[_vm._v("Ngày thanh toán: ")]),_c('v-menu',{ref:"menu",attrs:{"close-on-content-click":false,"return-value":_vm.date,"transition":"scale-transition","offset-y":"","min-width":"auto"},on:{"update:returnValue":function($event){_vm.date=$event},"update:return-value":function($event){_vm.date=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"prepend-icon":"mdi-calendar","readonly":"","dense":"","outlined":""},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-date-picker',{attrs:{"no-title":"","scrollable":""},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.menu = false}}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.$refs.menu.save(_vm.date)}}},[_vm._v(" OK ")])],1)],1)],1)],1),_c('div',{staticClass:"font-weight-bold mb-2"},[_vm._v("Ghi chú thanh toán:")]),_c('v-textarea',{attrs:{"outlined":"","dense":"","name":"input-7-1"},model:{value:(_vm.note),callback:function ($$v) {_vm.note=$$v},expression:"note"}})],1),_c('div',[_c('v-row',{staticClass:"align-center justify-center"},[_c('v-col',{attrs:{"cols":"9","md":"3","xl":"3","lg":"3"}},[_c('HnrButton',{staticClass:"white--text text-center",attrs:{"width":'100%',"color":'#000000',"text":'Lưu Trạng thái thanh toán'},on:{"hnrClickEvent":_vm.saveStatus}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }